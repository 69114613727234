:root {
  --wostin-font: "DM Sans", sans-serif;
  --wostin-font-two: "Amatic SC", cursive;
  --wostin-reey-font: "reeyregular";
  --wostin-gray: #878986;
  --wostin-gray-rgb: 135, 137, 134;
  --wostin-white: #ffffff;
  --wostin-white-rgb: 255, 255, 255;
  --wostin-black: #404a3d;
  --wostin-black-rgb: 64, 74, 61;
  --wostin-base: #95192a;
  --custom-brand-red: #95192ad7;
  --wostin-base-rgb: 85, 142, 76;
  --wostin-primary: #dcb466;
  --wostin-primary-rgb: 236, 221, 94;
  --wostin-extra: #f5f0e9;
  --wostin-extra-rgb: 245, 240, 233;
  --wostin-bdr-color: #ebe5dd;
  --wostin-bdr-color-rgb: 235, 229, 221;
}
* {
  margin: 0;
  padding: 0;
}
.main-nav {
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: #f9fafa;
}
.main-nav a {
  font-size: 56.5%;
  letter-spacing: 2px;
  font-family: nexaBold;
}
.main-nav p {
  font-size: 62.5%;
}
.main-nav ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.logo {
  display: grid;
  /* background-color: #3b5998; */
  grid-column: 1/3;
  /* display: flex; */
  justify-content: start;
  align-items: center;
  padding-left: 50px;
  cursor: pointer;
}
.logo h3 {
  font-size: 1.5rem;
  /* text-transform: uppercase; */
  background: rgb(66, 109, 110);
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  /* background: -webkit-linear-gradient(#082c21, rgba(6, 53, 156, 0.301)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 5px;
  margin-bottom: 1rem;
  margin-left: 10px;
}
.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu-link ul li {
  font-size: 1.8rem;
}
.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  margin-right: 35px;
  text-decoration: none;
  font-weight: 500;
}
.menu-link ul li p {
  padding-left: 20px;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.menu-link ul li p:hover {
  background-color: var(--wostin-white);
}

.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgb(119, 3, 3);
  /* text-decoration: underline; */
  border-bottom: 3px solid pink;
  transition: 0.5s;
  /* font-weight: 700; */
}
/* .activeu a {
    color: var(--wostin-base) !important;
  } */
.navbarScrollingDropdown div {
  background-color: #c2c1c1e8;
  width: 215px ;
}
.social-media {
    grid-column: 4/5;
  }
  
  .social-media ul {
    height: 5rem;
    display: grid;
    /* grid-template-columns: 3fr repeat(3, 1fr);   */
    align-items: center;
    justify-content: flex-end;
  }
  
  .social-media ul li {
    text-align: right;
  }
  .social-media .hamburger-menu {
    display: none;
  }
  .mySwiper{
    margin-bottom: 70px;
  }
  #study-abroad{
    text-align: justify;
    margin-bottom: 70px;
  }
  #top-countries-sec,.most-desired-courses{
    text-align: center;
    margin-bottom: 70px;
  }
  .footer{
    background-color: rgb(3, 3, 27);
    padding-top: 100px;
    margin-top: 100px;
  }
  .footer img{
    width: 100%;
    margin-top: -100px;
  }
  .list-unstyled a{
    text-decoration: none;
    color: #ffffff;
  }
  .list-unstyled a:hover{
    color: yellowgreen;
  }
  .page-header {
    position: relative;
    display: block;
    padding: 140px 0 140px;
    z-index: 1;
  }
  
  .page-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    /* padding: 0px 30px; */
  }
  
  .page-header-bg:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    /* background-color: rgba(53, 49, 49, 0.8); */
    z-index: -1;
  }
  
  .page-header__inner {
    position: relative;
    display: block;
    text-align: center;
    z-index: 15;
  }
  
  .page-header__inner h2 {
    font-size: 50px;
    color: var(--wostin-white);
    line-height: 60px;
    margin-bottom: 13px;
    font-weight: 700;
  }
  .commonBanner{
    margin-bottom: 70px;
  }
  .header-Text{
    font-weight: 900;
    margin-top: 10px;
  }
  .smallletter-text-header{
    color: #95192a;
    font-weight: 700;
    letter-spacing: 5px;
    display: block;
  }
  .top-countries-Card{
    border: 0px !important;
  }
  .top-countries-Card a{
    text-decoration: none;
    font-weight: 900;
  }
  .top-countries-Card-footer{
    background-color: #cccfcc !important;
    text-align: center;
  }
  .trusted-survice-Card-footer{
    background-color: #000000 !important;
    text-align: center;
  }
  .desire-courses{
    background-color: rgb(9, 9, 90);
    padding: 20px;
    margin: 5px;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
  }
  .popular-courses{
    margin-bottom: 70px;
  }
  .courses div, .study-sec div{
    text-align: justify;
    margin-bottom: 20px;
  }
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .menu-link ul,
  .social-media ul {
    display: none;
  }
}

@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }
  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }
  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }
  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .logo,
  .social-media ul {
    height: 7rem;
  }
  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }
  
  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 1s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }
  
  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }
  .logo,
  .social-media ul {
    height: 6rem;
  }
  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }
  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}
@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }
  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }
  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}
